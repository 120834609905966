// Imports
import React from 'react';

// CSS
import * as FooterStyles from './css/Footer.module.css';

// Date
const year = new Date().getFullYear();

// ======== Component ========
const Footer = () => {
	return (
		<footer className={`flex w100 small-text ${ FooterStyles.footer }`}>
			<ul className={`flex flex-row ${ FooterStyles.footerList }`}>
				<li><a href={'https://www.pilates.com/company/balanced-body-legal-notice'}>Legal</a></li>
				<li><a href={'https://www.pilates.com/company/balanced-body-privacy-policy'}>Privacy</a></li>
				<li><a href={'https://www.pilates.com/company/contact-us'}>Contact Us</a></li>
				<li><a href={'https://www.pilates.com'}>USA Site</a></li>
				<li>
					©
					{year}
					{' '}
					Balanced Body
				</li>
			</ul>
		</footer>
	);
};

export default Footer;
