// Imports
import React, { useContext } from 'react';
import { myContext } from './Provider';

// CSS
import * as HeaderStyles from './css/Header.module.css';

// Components
import Search from './Search';
import Button from './Button';

// SVG
import MobileLogo from '../assets/images/bb-logo-mobile.svg';
import SearchIcon from '../assets/images/icons/icon_search.svg';

// ======== Component ========
const Header = () => {
	// State
	const contextValue = useContext(myContext);

	// Change the context search value; this will close the nav accordions
	const handleSearch = val => {
		contextValue.handleModalSearch(val);
	};

	return (
		<header className={`flex flex-row flex-jc-fe w100 ${ HeaderStyles.header }`}>
			{/* Desktop */}
			<div className={`flex flex-ai-fe w100 ${ HeaderStyles.desktopSearch }`}>
				<Search searchAction={handleSearch} />
			</div>
			{/* Mobile */}
			<div className={`flex flex-row flex-jc-sb w100 ${ HeaderStyles.mobileHeader }`}>
				<div className={'flex flex-col flex-ai-fs'}>
					<MobileLogo className={HeaderStyles.mobileLogo} />
					<p className={`small-text ${ HeaderStyles.sidebarSubtitle }`}>United Kingdom</p>
				</div>
				<div className={'flex flex-row'}>
					<button
						aria-label={'search'}
						onClick={() => {
							contextValue.handleModalOpen({
								...contextValue.modalState,
								open: true,
								content: 'search',
							});
						}}
						className={HeaderStyles.mobileSearchButton}
					>
						<SearchIcon className={HeaderStyles.mobileSearchIcon} />
					</button>
					<Button
						text={'MENU'}
						type={'button'}
						action={() => { contextValue.handleMobileNavOpen(); }}
						styles={{
							paddingTop: '11px',
							paddingRight: '22px',
							paddingBottom: '12px',
							paddingLeft: '22px',
						}}
					/>
				</div>
			</div>
		</header>
	);
};

export default Header;
