// Imports
import React from 'react';
import { Helmet } from 'react-helmet';

// ======== Component ========
const SEO = props => {
	const {
		title,
	} = props;

	return (
	// Supports all valid head tags: title, base, meta, link, script, noscript, and style tags.
	// Supports attributes for body, html and title tags.
		<Helmet htmlAttributes={{ lang: 'en' }}>
			<meta charSet={'utf-8'} />
			<title>{title || 'Balanced Body UK'}</title>
			<meta name={'description'} content={'Balanced Body UK product inventory'} />
			<link rel={'canonical'} href={'https://balancedbodyequipment.uk/'} />
		</Helmet>
	);
};

export default SEO;
