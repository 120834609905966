// Imports
import React from 'react';
import PropTypes from 'prop-types';

// CSS
import * as LayoutStyles from './css/Layout.module.css';

// Components
import Header from './Header';
import Footer from './Footer';
import SEO from './SEO';

// ======== Component ========
const Layout = props => {
	const {
		title,
		children,
	} = props;
	return (
		<div className={`flex flex-jc-fs w100 ${ LayoutStyles.layout }`}>

			{/* SEO */}
			<SEO title={title} />

			{/* Main content */}
			<div className={'flex flex-row flex-ai-fs flex-jc-fe w100'}>

				{/* Header, Products & content */}
				<main className={`flex flex-jc-sb w100 ${ LayoutStyles.layoutMain }`}>
					<div className={'flex flex-ai-fs w100'}>
						<Header />
						{children}
					</div>
					<Footer />
				</main>
			</div>
		</div>
	);
};

// Prop validation
Layout.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string.isRequired,
};

export default Layout;
